<template>
  <div class="view">
    <router-link to="/" class="back"
      ><img class="desktop_form_close" src="@/assets/img/close.svg" alt="close"
    /></router-link>

    <div class="group">
      <label><strong>Writer:</strong></label>
      <h1>{{}}</h1>
    </div>

    <div class="group-col hide-on-tablet">
      <div class="group" style="width: 50%">
        <label class="inline"><strong>ArtistName:</strong></label>
      </div>
      <div class="group">
        <label class="inline"><strong>Society:</strong></label>
      </div>
      <div class="group">
        <label class="inline"><strong>IPI:</strong></label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    writer_id: {
      type: String,
      required: true,
    },
  },
  created() {
    console.log("this", this);
  },
};
</script>

<style lang="scss" scoped>
</style>